import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Not found" />

    <div className="error-message">
      <h1 className="error-message-title">404</h1>
      <h2>Page not found</h2>
      <Link to="/">Return to home page →</Link>
    </div>
  </Layout>
)

export default NotFoundPage
